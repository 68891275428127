<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Schedule Change</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="terms.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ selectedTermLabel }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ label, term } in terms" :key="term" @click="selectedTerm = term">
            <v-list-item-title>{{ label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else-if="selectedTerm !== ''" disabled text>{{ selectedTermLabel }}</v-btn>
    </v-toolbar>
    <v-alert v-if="isLoaded && terms.length === 0" type="error">It looks like you are not registered for any upcoming terms. Please use Banner for your initial course registration. If you need further assistance, please contact the Records office.</v-alert>
  </v-card>
</template>
<script>
import { ref, reactive, computed, onMounted, watch } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => root.$store.state.roles)

    const isLoaded = ref(false)
    const terms = ref([])
    const selectedTerm = ref('')
    const selectedTermLabel = computed(() => {
      const rec = terms.value.find(({ term }) => term === selectedTerm.value)
      if (rec) return rec.label
      return '**Select Term**'
    })

    onMounted(async () => {
      let pidm = user.value.pidm
      // Get possible terms from the term warehouse
      let { data: termData } = await root.$feathers.service('system/term').find({ query: { end: { $gt: new Date() }, $limit: 50, $sort: { start: 1 } } })
      let termCodes = termData.map(({ term }) => term)
      // Get term from confirmation warehouse
      let { data: detData } = await root.$feathers.service('student/term-detail').find({ query: { pidm, term: { $in: termCodes }, 'schedule.0': { $exists: true } } })
      if (detData.length > 0) {
        const detTerms = detData.map(({ term }) => term)
        terms.value = termData.filter(({ term }) => detTerms.includes(term))
        selectedTerm.value = terms.value[0].term
      }
      isLoaded.value = true
    })

    const changeData = reactive({})
    watch(selectedTerm, async (term) => {
      if (term == null || term === '') return
      // Check to see if they have a schedule change submission for the selected term
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { pidm: user.value.pidm, term } })
      // If they do, load it
      if (data.length > 0) {
        for (const field in data[0]) {
          changeData[field] = data[0][field]
        }
      } else {
        // If not, create one
        try {
          const data = await root.$feathers.service('student/schedule-change').create({ pidm: user.value.pidm, term, bannerId: user.value.bannerId, name: user.value.name })
          for (const field in data) {
            changeData[field] = data[field]
          }
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error building schedule change: ' + e })
          console.log(e)
        }
      }
      if (changeData.changes.length === 0) {
        // They have not started any changes yet
        console.log('No changes')
      } else {
        console.log('Has changes')
      }
    })

    return {
      user,
      roles,
      isLoaded,
      terms,
      selectedTerm,
      selectedTermLabel,
      changeData
    }
  }
}
</script>
